module.exports = [{
      plugin: require('/Applications/XAMPP/xamppfiles/htdocs/paolacarolina-gatsby/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/Applications/XAMPP/xamppfiles/htdocs/paolacarolina-gatsby/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.1.7","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"defaultLayouts":{"default":"/Applications/XAMPP/xamppfiles/htdocs/paolacarolina-gatsby/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":960,"linkImagesToOriginal":false,"wrapperStyle":false}}]},
    },{
      plugin: require('/Applications/XAMPP/xamppfiles/htdocs/paolacarolina-gatsby/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Applications/XAMPP/xamppfiles/htdocs/paolacarolina-gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-102170809-1"},
    },{
      plugin: require('/Applications/XAMPP/xamppfiles/htdocs/paolacarolina-gatsby/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-837151511"]},
    },{
      plugin: require('/Applications/XAMPP/xamppfiles/htdocs/paolacarolina-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Applications/XAMPP/xamppfiles/htdocs/paolacarolina-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
